/* ==|====================
	 Dashboard | Contien tout les classes globleau dans le dashboard
	 ======================= */

// .wdz-dashboard {}

// Block Statistiques
// ==|===============
// .stats {}

// .sats-item {}

// Les formulaire
// ==|====================
// Formulaire de creation d'article
.form-text-input_height {
	min-height: 200px;
	resize: vertical;
}

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
// @media (min-width: 768px) {}

// Pour ceux qui ont une résolution inférieure à 767px
// @media screen and (max-width: 767px) {}
