/* ==|====================
	 Base
	 ======================= */

//== Font google
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

//== complement reset
button,
input,
select,
textarea {
	font: inherit;
}

body {
	font-family: "Roboto", $font-family-sans-serif;
	font-size: em($font-size-base);
	line-height: $line-height-base;
	color: $wdz-body_color;
	background-color: $wdz-body_bg;
	background-color: #f1f2f7;
}

//== Liens
a {
	text-decoration: none;
	color: $wdz-lien;

	@include hover {
		color: $wdz-lien_hover;
	}
}

//== Typography
strong {
	font-weight: 500;
}
