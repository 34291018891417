/* ==|====================
	 Content
	 ======================= */

// Header du content
.content-header {
	background: #99a7b1;
}

// Container du site
.content {
	position: relative;
	padding: 15px;
	margin: 0 auto;
	max-width: 1260px;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
@media (min-width: 768px) {
	// Container du site
	.content {
		padding: 15px 30px;
	}
}

// Pour ceux qui ont une résolution inférieure à 767px
// @media screen and (max-width: 767px) {}
