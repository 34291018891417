/* ==|====================
	 Tutoriels
	 ======================= */

//== TOUT LES TUTORIELS
//=====================
// Les couleurs des sujets
.sujet-html { .card-cover { background-color: $html; } }
.sujet-css { .card-cover { background-color: $css; } }
.sujet-javascript { .card-cover { background-color: $javascript; } }
.sujet-php { .card-cover { background-color: $php; } }
.sujet-ruby { .card-cover { background-color: $ruby; } }
.sujet-nodejs { .card-cover { background-color: $nodejs; } }
.sujet-web { .card-cover { background-color: $web; } }
.sujet-entrepreneur { .card-cover { background-color: $entrepreneur; } }

// Post article
.tutorial-box-overlay {
	&.sujet-html { background-color: $html; }
	&.sujet-css { background-color: $css; }
	&.sujet-javascript { background-color: $javascript; }
	&.sujet-php { background-color: $php; }
	&.sujet-ruby { background-color: $ruby; }
	&.sujet-nodejs { background-color: $nodejs; }
	&.sujet-web { background-color: $web; }
	&.sujet-entrepreneur { background-color: $entrepreneur; }
}

//== TUTORIELS POSTER
//===================
// Tutoriel Box
// .tutorial-box {}

.tutorial-box-overlay {
	display: block;
	position: relative;
	min-height: 250px;
	padding: 20px;
	margin-bottom: 30px;
	color: #FFF;
	background: url('../images/background/intro-cover.png') center rgba(47, 50, 65, .9);

	&::after {
		content: " ";
		display: table;
		clear: both;
	}
}

.tutorial-box_info {
	height: 100%;
}

.tutorial-box_author {
	position: relative;
	height: 100%;
	float: right;
	margin-bottom: rem(25);
	font-size: rem(14);
}

.tutorial-box_info-category {
	margin-top: 50px;
	font-size: 12px;
	text-transform: uppercase;
}

.tutorial-box_info-title { font-size: rem(31); }

.tutorial-box_author-photo {
	position: absolute;
	width: 40px;
	height: 40px;
	left: -50px;
	border: 2px solid #fff;
	color: $wdz-body_color;
}

.tutorial-box_info-breadcrumb {
	position: absolute;
	bottom: 0;
	padding: rem(16) 0;

	// Liens fil d'Ariane
	a {
		// color: #788694;
		color: #FFF;

		// Hover
		@include hover {
			color: lighten(#788694, 15%);
		}
	}
	// Lien active
	li.active a {
		color: darken(#404852, 10%);
	}
	li + li::before { color: #FFF; }
}

//== Header post du tutoriel
.tutorial-post_header {
	// padding-left: 15px;
	// padding-right: 15px;
}

.count-container {
	display: inline-block;
	float: right;
}

//== Modification du tutoriel
.post-edit-admin { margin-bottom: rem(16); }

// Welcome
.tutorials-welcome {
	display: flex;
	align-items: center;
	justify-content: center;

	.welcome {
		width: 800px;
		font-size: 18px;
	}
}

//== ERREURS DES TUTORIELS
//========================
.tutorials-error {
	text-align: center;

	p {
		margin-bottom: rem(16);
	}
}

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
@media (min-width: 768px) {
	//== ARTICLES POST
	//================
	// Tutoriel Box
	.tutorial-box_info {
		width: 40.66667%;
	}

	.button-edit,
	.button-add { float: right; }
}

// Pour ceux qui ont une résolution inférieure à 767px
@media screen and (max-width: 767px) {
	//== Post Sidebar
	.post-sidebar {
		margin-bottom: 30px;
	}

	//== Post Content
	.post-content {
		padding: 0;
		border: 0;
	}
}

