/* ==|====================
   Mixins
   ======================= */

//___ Hovers ___
@mixin hover {
	&:hover { @content; }
}

//___ Hovers & Focus ___
@mixin hover-focus {
	&:focus,
	&:hover { @content; }
}

//___ Hovers, Focus, Active ___
@mixin hover-focus-active {
	&:focus,
	&:active,
	&:hover { @content; }
}

//___ Hovers, Focus, * ___
@mixin plain-hover-focus {
	&,
	&:focus,
	&:hover { @content; }
}

//___ Focus ___
@mixin focus {
	&:focus { @content; }
}

//___ Border Radius ___
@mixin border-radius($radius) {
	border-radius: $radius;
}

//___ Button ___
//== Outline
@mixin button-outline($color) {
	color: $color;
	background-image: none;
	background-color: transparent;
	border-color: $color;

	@include hover-focus {
		color: #fff;
		background-color: $color;
		border-color: $color;
	}
}
//== Variant
@mixin button-variant($background, $border) {
	color: #fff;
	background-color: $background;
	border-color: $border;

	@include hover-focus {
		color: #fff;
		background-color: darken($background, 11%);
		border-color: darken($border, 13%);
	}
}

//___ Clearfix ___
@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

//___ Alert ___
//== Variant
@mixin alert($body-color, $border, $background) {
	color: $body-color;
	border-color: $border;
	background-color: $background;

	hr {
		border-top-color: darken($border, 5%);
	}

	.alert-link {
		color: darken($body-color, 10%);
	}
}

//== Outline
@mixin alert-outline($body-color, $border) {
	color: $body-color;
	border-color: $border;
	background-color: transparent;

	hr {
		border-top-color: darken($border, 5%);
	}

	.alert-link {
		color: darken($body-color, 10%);
	}
}

//** ==|====================
@import "mixins/breakpoints";
@import "mixins/grid-framework";
