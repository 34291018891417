@charset "UTF-8";
/* ==|====================
	 Variables
	 ======================= */
/* ==|====================
   Functions
   ======================= */
/* ==|====================
   Mixins
   ======================= */
/* ==|====================
   Breakpoint
   ======================= */
/* ==|====================
   Grid Framework
   ======================= */
/* ==|====================
   ResetCss | Réinitialisation
   ======================= */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: sans-serif;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

main,
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block; }

small {
  font-size: 80%; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

img {
  border-style: none;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

ul {
  list-style: none; }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

/*
 NE MODIFIEZ PAS DIRECTEMENT!
 Généré automatiquement par iconFont.js de gulpfile\tasks\iconFont\template.scss
*/
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.28571429em;
  text-align: center; }

.icon-account-user:before {
  content: "\EA01"; }

.icon-logo:before {
  content: "\EA02"; }

.icon-menu:before {
  content: "\EA03"; }

/* ==|====================
	 Base
	 ======================= */
button,
input,
select,
textarea {
  font: inherit; }

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: #404852;
  background-color: #f6f7f8;
  background-color: #f1f2f7; }

a {
  text-decoration: none;
  color: #576366; }
  a:hover {
    color: #343b3d; }

strong {
  font-weight: 500; }

/* ==|====================
   Typography, Headings
   ======================= */
h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.75rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.25rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

/* ==|====================
   Grille
   ======================= */
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container::after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1140px; } }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row::after {
    content: "";
    display: table;
    clear: both; }

.col-s-1, .col-s-2, .col-s-3, .col-s-4, .col-s-5, .col-s-6, .col-s-7, .col-s-8, .col-s-9, .col-s-10, .col-s-11, .col-s-12, .col-m-1, .col-m-2, .col-m-3, .col-m-4, .col-m-5, .col-m-6, .col-m-7, .col-m-8, .col-m-9, .col-m-10, .col-m-11, .col-m-12, .col-l-1, .col-l-2, .col-l-3, .col-l-4, .col-l-5, .col-l-6, .col-l-7, .col-l-8, .col-l-9, .col-l-10, .col-l-11, .col-l-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-s-1, .col-s-2, .col-s-3, .col-s-4, .col-s-5, .col-s-6, .col-s-7, .col-s-8, .col-s-9, .col-s-10, .col-s-11, .col-s-12 {
  float: left; }

.col-s-1 {
  width: 8.33333%; }

.col-s-2 {
  width: 16.66667%; }

.col-s-3 {
  width: 25%; }

.col-s-4 {
  width: 33.33333%; }

.col-s-5 {
  width: 41.66667%; }

.col-s-6 {
  width: 50%; }

.col-s-7 {
  width: 58.33333%; }

.col-s-8 {
  width: 66.66667%; }

.col-s-9 {
  width: 75%; }

.col-s-10 {
  width: 83.33333%; }

.col-s-11 {
  width: 91.66667%; }

.col-s-12 {
  width: 100%; }

.col-s-center {
  margin: 0 auto;
  float: none; }

@media (min-width: 768px) {
  .col-m-1, .col-m-2, .col-m-3, .col-m-4, .col-m-5, .col-m-6, .col-m-7, .col-m-8, .col-m-9, .col-m-10, .col-m-11, .col-m-12 {
    float: left; }
  .col-m-1 {
    width: 8.33333%; }
  .col-m-2 {
    width: 16.66667%; }
  .col-m-3 {
    width: 25%; }
  .col-m-4 {
    width: 33.33333%; }
  .col-m-5 {
    width: 41.66667%; }
  .col-m-6 {
    width: 50%; }
  .col-m-7 {
    width: 58.33333%; }
  .col-m-8 {
    width: 66.66667%; }
  .col-m-9 {
    width: 75%; }
  .col-m-10 {
    width: 83.33333%; }
  .col-m-11 {
    width: 91.66667%; }
  .col-m-12 {
    width: 100%; }
  .col-m-center {
    margin: 0 auto;
    float: none; } }

@media (min-width: 1024px) {
  .col-l-1, .col-l-2, .col-l-3, .col-l-4, .col-l-5, .col-l-6, .col-l-7, .col-l-8, .col-l-9, .col-l-10, .col-l-11, .col-l-12 {
    float: left; }
  .col-l-1 {
    width: 8.33333%; }
  .col-l-2 {
    width: 16.66667%; }
  .col-l-3 {
    width: 25%; }
  .col-l-4 {
    width: 33.33333%; }
  .col-l-5 {
    width: 41.66667%; }
  .col-l-6 {
    width: 50%; }
  .col-l-7 {
    width: 58.33333%; }
  .col-l-8 {
    width: 66.66667%; }
  .col-l-9 {
    width: 75%; }
  .col-l-10 {
    width: 83.33333%; }
  .col-l-11 {
    width: 91.66667%; }
  .col-l-12 {
    width: 100%; }
  .col-l-center {
    margin: 0 auto;
    float: none; } }

/* ==|====================
   Base
   ======================= */
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .nav > li > a {
    display: inline-block; }
    .nav > li > a:focus, .nav > li > a:hover {
      text-decoration: none; }

.nav-inline > li {
  display: inline-block; }

.nav-inline > li + li,
.nav-inline > a + a {
  margin-left: 16px; }

/* ==|====================
   Buutons
   ======================= */
.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  font-weight: normal;
  line-height: 1.25;
  border-radius: 0.1875rem;
  text-align: center;
  user-select: none;
  border: 2px solid transparent; }
  .button:focus, .button:hover {
    text-decoration: none; }

.button-divider {
  position: absolute;
  width: 22px;
  height: 22px;
  margin-top: 5px;
  margin-left: -13px;
  text-align: center;
  font-size: 0.75rem;
  z-index: 2;
  line-height: 22px;
  border-radius: 1.375rem;
  color: rgba(22, 24, 27, 0.54);
  background-color: #fff;
  user-select: none; }

.button-first-outline {
  color: #4A89DC;
  background-image: none;
  background-color: transparent;
  border-color: #4A89DC; }
  .button-first-outline:focus, .button-first-outline:hover {
    color: #fff;
    background-color: #4A89DC;
    border-color: #4A89DC; }

.button-first-outline {
  color: #4A89DC;
  background-image: none;
  background-color: transparent;
  border-color: #4A89DC; }
  .button-first-outline:focus, .button-first-outline:hover {
    color: #fff;
    background-color: #4A89DC;
    border-color: #4A89DC; }

.button-info-outline {
  color: #3BAFDA;
  background-image: none;
  background-color: transparent;
  border-color: #3BAFDA; }
  .button-info-outline:focus, .button-info-outline:hover {
    color: #fff;
    background-color: #3BAFDA;
    border-color: #3BAFDA; }

.button-success-outline {
  color: #8CC152;
  background-image: none;
  background-color: transparent;
  border-color: #8CC152; }
  .button-success-outline:focus, .button-success-outline:hover {
    color: #fff;
    background-color: #8CC152;
    border-color: #8CC152; }

.button-warning-outline {
  color: #F6BB42;
  background-image: none;
  background-color: transparent;
  border-color: #F6BB42; }
  .button-warning-outline:focus, .button-warning-outline:hover {
    color: #fff;
    background-color: #F6BB42;
    border-color: #F6BB42; }

.button-danger-outline {
  color: #DA4453;
  background-image: none;
  background-color: transparent;
  border-color: #DA4453; }
  .button-danger-outline:focus, .button-danger-outline:hover {
    color: #fff;
    background-color: #DA4453;
    border-color: #DA4453; }

.button-dark-outline {
  color: #2f353b;
  background-image: none;
  background-color: transparent;
  border-color: #2f353b; }
  .button-dark-outline:focus, .button-dark-outline:hover {
    color: #fff;
    background-color: #2f353b;
    border-color: #2f353b; }

.button-first {
  color: #fff;
  background-color: #4A89DC;
  border-color: #4A89DC; }
  .button-first:focus, .button-first:hover {
    color: #fff;
    background-color: #276cc7;
    border-color: #2567bf; }

.button-info {
  color: #fff;
  background-color: #3BAFDA;
  border-color: #3BAFDA; }
  .button-info:focus, .button-info:hover {
    color: #fff;
    background-color: #2391ba;
    border-color: #218ab1; }

.button-success {
  color: #fff;
  background-color: #8CC152;
  border-color: #8CC152; }
  .button-success:focus, .button-success:hover {
    color: #fff;
    background-color: #70a13a;
    border-color: #6b9a37; }

.button-warning {
  color: #fff;
  background-color: #F6BB42;
  border-color: #F6BB42; }
  .button-warning:focus, .button-warning:hover {
    color: #fff;
    background-color: #f3a80c;
    border-color: #eba10b; }

.button-danger {
  color: #fff;
  background-color: #DA4453;
  border-color: #DA4453; }
  .button-danger:focus, .button-danger:hover {
    color: #fff;
    background-color: #c02635;
    border-color: #b72433; }

.button-dark {
  color: #fff;
  background-color: #2f353b;
  border-color: #2f353b; }
  .button-dark:focus, .button-dark:hover {
    color: #fff;
    background-color: #16191c;
    border-color: #121416; }

/* ==|====================
   Forms
   ======================= */
.input-label {
  display: inline-block;
  margin-bottom: 0.5rem; }

.input-text,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #656D78;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0.125rem; }
  .input-text:focus,
  textarea:focus {
    border-color: #0DAFE0;
    outline: none; }

select:not([size]):not([multiple]) {
  height: 2.5rem; }

select:focus::-ms-value {
  color: #656D78;
  background-color: #FFF; }

.form-item {
  position: relative;
  margin-bottom: 1rem; }

.form-inline .form-item {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle; }

.form-inline .input-text,
.form-inline textarea {
  display: inline-block;
  width: auto;
  vertical-align: middle; }

.form-text-input {
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  outline: 0;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid #e8ebed;
  background: #e8ebed;
  transition: .2s; }

.input-text:focus,
textarea:focus {
  background: #fff;
  border-color: #d4d9dd; }

/* ==|====================
	 Alert
	 ======================= */
.alert {
  padding: 0.4375rem;
  margin-bottom: 0.875rem;
  border: 2px solid transparent;
  font-size: 1rem;
  border-radius: 0.1875rem; }

.alert-first {
  color: #1f57a1;
  border-color: #8ab3e8;
  background-color: #9fc1ed; }
  .alert-first hr {
    border-top-color: #75a5e4; }
  .alert-first .alert-link {
    color: #174076; }

.alert-info {
  color: #1c7393;
  border-color: #73c6e5;
  background-color: #91d2ea; }
  .alert-info hr {
    border-top-color: #5dbde0; }
  .alert-info .alert-link {
    color: #145168; }

.alert-success {
  color: #587f2e;
  border-color: #b1d58a;
  background-color: #bedc9d; }
  .alert-success hr {
    border-top-color: #a5ce78; }
  .alert-success .alert-link {
    color: #3e5a20; }

.alert-warning {
  color: #c88a0a;
  border-color: #f9d58b;
  background-color: #fbdea3; }
  .alert-warning hr {
    border-top-color: #f8cd73; }
  .alert-warning .alert-link {
    color: #986807; }

.alert-danger {
  color: #9a1e2b;
  border-color: #e7848e;
  background-color: #eb99a1; }
  .alert-danger hr {
    border-top-color: #e26f7a; }
  .alert-danger .alert-link {
    color: #6f161f; }

.alert-outline-first {
  color: #1f57a1;
  border-color: #8ab3e8;
  background-color: transparent; }
  .alert-outline-first hr {
    border-top-color: #75a5e4; }
  .alert-outline-first .alert-link {
    color: #174076; }

.alert-outline-info {
  color: #1c7393;
  border-color: #73c6e5;
  background-color: transparent; }
  .alert-outline-info hr {
    border-top-color: #5dbde0; }
  .alert-outline-info .alert-link {
    color: #145168; }

.alert-outline-success {
  color: #587f2e;
  border-color: #b1d58a;
  background-color: transparent; }
  .alert-outline-success hr {
    border-top-color: #a5ce78; }
  .alert-outline-success .alert-link {
    color: #3e5a20; }

.alert-outline-warning {
  color: #c88a0a;
  border-color: #f9d58b;
  background-color: transparent; }
  .alert-outline-warning hr {
    border-top-color: #f8cd73; }
  .alert-outline-warning .alert-link {
    color: #986807; }

.alert-outline-danger {
  color: #9a1e2b;
  border-color: #e7848e;
  background-color: transparent; }
  .alert-outline-danger hr {
    border-top-color: #e26f7a; }
  .alert-outline-danger .alert-link {
    color: #6f161f; }

/* ==|====================
	 Flexbox
	 ======================= */
.aligner {
  display: flex;
  align-items: center;
  justify-content: center; }

.aligner-item {
  max-width: 50%; }

.aligner-item--top {
  align-self: flex-start; }

.aligner-item--bottom {
  align-self: flex-end; }

/* ==|====================
	 UI Components
	 ======================= */
button {
  appearance: none;
  outline: none;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background: transparent; }

.colours,
.colours-bg {
  animation-iteration-count: infinite; }

.colours {
  animation: colour 55s 30 ease-in-out; }

.colours-bg {
  animation: colour-bg 48s 30 ease-in-out; }

@keyframes colour {
  0% {
    color: #3498db; }
  15% {
    color: #2ecc71; }
  28% {
    color: #1abc9c; }
  41% {
    color: #9b59b6; }
  53% {
    color: #e74c3c; }
  65% {
    color: #f39c12; }
  78% {
    color: #e67e22; }
  90% {
    color: #2ecc71; }
  100% {
    color: #3498db; } }

@keyframes colour-bg {
  0% {
    background-color: #3498db; }
  15% {
    background-color: #2ecc71; }
  28% {
    background-color: #1abc9c; }
  41% {
    background-color: #9b59b6; }
  53% {
    background-color: #e74c3c; }
  65% {
    background-color: #f39c12; }
  78% {
    background-color: #e67e22; }
  90% {
    background-color: #2ecc71; }
  100% {
    background-color: #3498db; } }

.no-scroll {
  overflow: hidden; }

.mixed-box {
  position: relative;
  padding: 15px;
  border-radius: 0.1875rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  background: #FFF; }

.box {
  position: relative;
  top: 0;
  padding: 1.25em;
  margin-bottom: 1em;
  border-radius: 0.1875em;
  box-shadow: 0 2px 0 0 #CCCFDB;
  background: #fff;
  transition: all .2s; }

.card-list {
  /* margin: 0 -15px; */ }

.card {
  position: relative;
  display: block;
  margin-bottom: 30px; }

.card-box {
  display: block;
  position: relative;
  min-height: 225px;
  top: 0;
  padding: 70px 15px 10px;
  border-radius: 5px 5px 4px 4px;
  box-shadow: 0 2px 0 0 #CCCFDB;
  transition: all .2s; }
  .card-box:hover {
    top: -2px;
    box-shadow: 0 4px 0 0 #CCCFDB; }

.card-box:not(.has-topic-background-color) {
  background: #FFF; }

.card-cover {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 58px;
  line-height: 58px;
  padding: 0 15px;
  border-radius: 4px 4px 0 0;
  color: #fff;
  background: url("../images/background/intro-cover.png") center rgba(47, 50, 65, 0.9); }

.card-icon {
  position: relative;
  height: 20px;
  width: 20px;
  top: 5px;
  margin-right: 15px;
  font-size: 25px; }

.card-reading_time {
  position: absolute;
  padding-left: 10px;
  top: 0px;
  right: 30px;
  font-weight: 500;
  text-align: right;
  color: #FFF; }

.card-type {
  display: block;
  margin: 0 0 10px;
  line-height: 12px;
  font-size: 14px;
  color: #a4afb2; }

.card-title {
  margin: 0 0 10px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  color: #4b5658; }

.card-description {
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.card-actions {
  display: block;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 10px;
  z-index: 1;
  color: #576366; }
  .card-actions a:hover {
    color: #576366; }

.card-small .card-box {
  min-height: 150px; }

.breadcrumb {
  display: inline-block;
  margin: 0;
  font-size: 14px;
  list-style: none; }
  .breadcrumb i {
    position: relative;
    top: 1px;
    right: 5px;
    font-size: 16px; }
  .breadcrumb li {
    display: inline; }
    .breadcrumb li.active a {
      cursor: default;
      color: #c5cbcc; }
      .breadcrumb li.active a:hover {
        text-decoration: none; }
    .breadcrumb li a:hover {
      text-decoration: underline; }
  .breadcrumb .separator {
    padding: 0 5px; }
  .breadcrumb li + li::before {
    padding: 0 5px;
    color: #c5cbcc;
    content: ">"; }

.img-circle {
  border-radius: 50%; }

.avatar-user {
  display: inline-block;
  box-sizing: content-box;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 8px;
  border-radius: 2.5rem;
  font-size: 1.25em;
  line-height: 2.5rem;
  color: #FFF;
  background: #e7edf3; }

.text-center {
  text-align: center; }

.uppercase {
  text-transform: uppercase; }

.margin-top {
  margin-top: 1em; }

.link-heading {
  margin: 12px 15px; }

.link-separated {
  display: block;
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05); }

.wdz_A:hover {
  fill: #2ecc71; }

.wdz_B:hover {
  fill: #4A89DC; }

.wdz_C:hover {
  fill: #5D9CEC; }

.wdz_D:hover {
  fill: #3BAFDA; }

.wdz_E:hover {
  fill: #4FC1E9; }

.wdz_F:hover {
  fill: #37BC9B; }

.wdz_G:hover {
  fill: #48CFAD; }

.wdz_H:hover {
  fill: #8CC152; }

.wdz_I:hover {
  fill: #A0D468; }

.wdz_J:hover {
  fill: #F6BB42; }

.wdz_K:hover {
  fill: #FFCE54; }

.wdz_L:hover {
  fill: #E9573F; }

.wdz_M:hover {
  fill: #FC6E51; }

.wdz_N:hover {
  fill: #DA4453; }

.wdz_O:hover {
  fill: #ED5565; }

.wdz_P:hover {
  fill: #967ADC; }

.wdz_Q:hover {
  fill: #AC92EC; }

.wdz_R:hover {
  fill: #D770AD; }

.wdz_S:hover {
  fill: #EC87C0; }

/* ==|====================
	 Header
	 ======================= */
.page-header {
  position: relative;
  min-height: 45px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #FFF;
  border-bottom: 1px solid rgba(212, 218, 223, 0.2); }

.mobile-name-logo {
  display: none; }

.section-header {
  display: flex;
  align-items: center; }

.main-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 0 30px; }

.navigation-menu {
  display: flex; }

.navigation-item.active .navigation-link {
  color: #343b3d;
  opacity: 1; }

.navigation-link {
  padding: 0 16px;
  text-transform: uppercase;
  font-size: 14px;
  opacity: .7;
  transition: .3s; }
  .navigation-link:hover {
    opacity: 1; }

.logo {
  height: 45px;
  width: auto; }

.logo-link {
  display: flex;
  align-items: center; }

.button-menu-mobile {
  display: none;
  margin-left: 20px; }

.hamburger {
  width: 30px;
  height: 30px; }

.hamburger-bar {
  display: block;
  position: absolute;
  width: 22px;
  height: 3px;
  border-radius: 2px;
  background-color: #AAA;
  transition: .3s ease-in-out; }
  .hamburger-bar::before, .hamburger-bar::after {
    content: '';
    position: absolute;
    display: block;
    width: 22px;
    height: 3px;
    border-radius: 2px;
    background-color: #AAA;
    transition: .3s ease-in-out; }
  .hamburger-bar::before {
    top: -7px; }
  .hamburger-bar::after {
    bottom: -7px; }

.show-menu .hamburger-bar {
  background-color: transparent; }
  .show-menu .hamburger-bar::before {
    top: 0;
    transform: rotate(45deg); }
  .show-menu .hamburger-bar::after {
    bottom: 0;
    transform: rotate(-45deg); }

.header-connexion {
  font-size: 14px; }
  .header-connexion button {
    color: #576366; }

.signin-button {
  display: flex;
  align-items: center;
  justify-content: center; }

.signin-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem; }

.icon-background-circle {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #464d51; }

.signin-icon {
  display: block;
  position: absolute;
  font-size: 30px;
  color: #e7edf3; }

.sign-up {
  padding: 5px 10px;
  margin-top: -5px;
  margin-left: 10px;
  font-size: 14px;
  border: 0;
  border: 2px solid #0086BA;
  color: #0086BA;
  transform: translateZ(0); }
  .sign-up::after, .sign-up::before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: -1;
    content: "";
    background: #0086BA;
    transition: all 300ms ease-in-out; }
  .sign-up::before {
    left: 0; }
  .sign-up::after {
    right: 0; }
  .sign-up:hover {
    color: #FFF; }
    .sign-up:hover::before, .sign-up:hover::after {
      width: 51%; }

.connected-button {
  display: flex;
  align-items: center; }

.logout {
  margin-left: 1rem;
  color: #DA4453; }
  .logout:hover {
    color: #c42737; }

@media screen and (max-width: 767px) {
  .mobile-name-logo {
    display: block;
    padding: 0 25px;
    line-height: 55px;
    font-weight: 500;
    letter-spacing: 25px;
    text-transform: uppercase;
    text-align: center;
    color: #FFF; }
  .navigation {
    position: fixed;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1100;
    transform: translate3d(-250px, 0, 0);
    background: #0086BA; }
  .navigation-menu {
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 55px; }
  .navigation-item.active .navigation-link {
    color: #0086BA;
    background: #FFF; }
  .navigation-item:nth-child(1) {
    transform: translate3d(0, 1000px, 0); }
  .navigation-item:nth-child(2) {
    transform: translate3d(0, 1500px, 0); }
  .navigation-item:nth-child(3) {
    transform: translate3d(0, 2100px, 0); }
  .navigation-item:nth-child(4) {
    transform: translate3d(0, 2800px, 0); }
  .navigation-item:nth-child(5) {
    transform: translate3d(0, 3600px, 0); }
  .navigation-item:nth-child(6) {
    transform: translate3d(0, 4500px, 0); }
  .navigation-item:nth-child(7) {
    transform: translate3d(0, 5500px, 0); }
  .navigation-link {
    display: block;
    width: 100%;
    padding: 10px 20px;
    opacity: 1;
    color: #FFF; }
    .navigation-link:hover {
      color: #0086BA;
      background: #FFF; }
  .button-text {
    display: none; }
  .button-menu-mobile {
    display: block; }
  .show-menu .navigation {
    transform: translate3d(0, 0, 0);
    transition: transform .8s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); }
  .show-menu .navigation-item {
    transform: translate3d(0, 0, 0);
    transition: transform .8s;
    transition-duration: .9s; }
  .show-menu .main-content::before {
    z-index: 10;
    opacity: 1;
    background: rgba(0, 0, 0, 0.5); }
  .main-content::before {
    /* position: absolute; */
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    transition: opacity .8s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); } }

/* ==|====================
	 Content
	 ======================= */
.content-header {
  background: #99a7b1; }

.content {
  position: relative;
  padding: 15px;
  margin: 0 auto;
  max-width: 1260px; }
  .content::after {
    content: "";
    display: table;
    clear: both; }

@media (min-width: 768px) {
  .content {
    padding: 15px 30px; } }

/* ==|====================
	 Footer
	 ======================= */
.page-footer {
  position: relative;
  padding: 30px 0;
  text-align: center;
  border-top: 1px solid rgba(98, 98, 98, 0.2);
  background: white; }

.footer-links li {
  display: inline-block; }
  .footer-links li a {
    color: #404852; }

.footer-links li + li::before {
  content: '|';
  margin: 0 10px;
  color: black;
  opacity: .2; }

/* ==|====================
	 Dashboard | Contien tout les classes globleau dans le dashboard
	 ======================= */
.form-text-input_height {
  min-height: 200px;
  resize: vertical; }

/* ==|====================
	 Codes highlight
	 ======================= */
/* ==|====================
	 landing page
	 ======================= */
.wdz-homepage .page-header {
  position: absolute;
  color: white;
  box-shadow: none;
  border: none;
  background: transparent; }

.wdz-homepage .navigation-item.active .navigation-link {
  color: white; }

.wdz-homepage .navigation-link {
  color: white; }

.hero-welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  color: #FFF;
  background: url("../images/background/bg.jpg") center no-repeat transparent;
  background-size: cover; }
  .hero-welcome h1 {
    font-size: 2.5em; }
  .hero-welcome small {
    font-size: 1.5625em; }
  .hero-welcome p {
    font-size: 1.25em; }
  .hero-welcome::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .7;
    background: #191919; }

.hero-inside {
  position: relative; }

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #fff; }
  .section .container {
    max-width: 100%; }

.section-heading {
  max-width: 700px;
  margin: 0 auto 50px;
  text-align: center; }
  .section-heading small {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    opacity: .6; }

.section-separator {
  height: 2px;
  width: 80px;
  margin: 20px auto auto;
  background-color: rgba(0, 0, 0, 0.07); }

.section-welcome {
  max-width: 612px;
  margin: 0 auto;
  line-height: 28px;
  text-align: center; }

.topic-cst {
  color: #39ADD1; }

.topic-cst_background-color {
  background: #39ADD1; }

.topic-arq {
  color: #c25975; }

.topic-arq_background-color {
  background: #c25975; }

.topic-srm {
  color: #2c9676; }

.topic-srm_background-color {
  background: #2c9676; }

.services-item {
  display: block;
  position: relative;
  margin-bottom: 30px;
  text-align: center; }

.services-item_link {
  display: block;
  position: relative;
  padding: 30px 20px;
  min-height: 200px;
  border-radius: 4px;
  background: #FFF; }
  .services-item_link:hover {
    color: #576366; }

.services-item_title {
  font-size: 22px; }

.services-item_description {
  margin-top: 1.5625rem; }

.section-banner {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: white;
  background: rgba(0, 0, 0, 0.1); }

.banner-contact {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center; }
  .banner-contact .button {
    margin-bottom: 0.3125rem; }
  .banner-contact small {
    color: #b5b9bf; }
    .banner-contact small a {
      color: #0086BA; }

.section-banner_inner h2 {
  margin-bottom: 1rem; }

.button-banner {
  color: white;
  background-image: none;
  background-color: transparent;
  border-color: white; }
  .button-banner:focus, .button-banner:hover {
    color: black;
    background-color: white;
    border-color: white; }

.bg-grey {
  background-color: #f8f8f8; }

/* ==|====================
	 Connexion / s'inscrire
	 ======================= */
.login-content {
  width: 515px; }

.login a:hover,
.register a:hover,
.requestSetNewPassword a:hover {
  text-decoration: underline; }

.login .input-label,
.register .input-label,
.requestSetNewPassword .input-label {
  margin-bottom: 2px;
  font-weight: 500; }

.form-heading p,
.form-footer p {
  font-size: 14px; }

.form-heading {
  position: relative;
  padding-bottom: 15px; }
  .form-heading h1 {
    font-size: 20px;
    color: #282e2f; }
  .form-heading p {
    margin: 5px 0 0;
    line-height: 1.6; }

.form-footer {
  padding: 0.625rem 0.9375rem;
  margin: 1.5625rem -0.9375rem -0.625rem -0.9375rem;
  border-top: 1px solid rgba(231, 237, 243, 0.8); }

p.forgot-password {
  padding: 8px 0;
  float: right;
  line-height: 1.25;
  font-size: 14px;
  color: #7b8b8e; }

.wdz-login_ .login-content {
  width: 100%; }

/* ==|====================
	 Contact
	 ======================= */
.contact-content p {
  margin: 10px 0;
  font-size: 0.875rem; }

.contact-content .input-label {
  margin-bottom: 2px; }

.contact-content textarea {
  min-height: 110px;
  resize: vertical; }

.contact-content .button {
  margin-top: 10px; }

/* ==|====================
	 Tutoriels
	 ======================= */
.sujet-html .card-cover {
  background-color: #eb7728; }

.sujet-css .card-cover {
  background-color: #e59a13; }

.sujet-javascript .card-cover {
  background-color: #ffd78f; }

.sujet-php .card-cover {
  background-color: #7D669E; }

.sujet-ruby .card-cover {
  background-color: #e15258; }

.sujet-nodejs .card-cover {
  background-color: #95D26C; }

.sujet-web .card-cover {
  background-color: #637a91; }

.sujet-entrepreneur .card-cover {
  background-color: #39ADD1; }

.tutorial-box-overlay.sujet-html {
  background-color: #eb7728; }

.tutorial-box-overlay.sujet-css {
  background-color: #e59a13; }

.tutorial-box-overlay.sujet-javascript {
  background-color: #ffd78f; }

.tutorial-box-overlay.sujet-php {
  background-color: #7D669E; }

.tutorial-box-overlay.sujet-ruby {
  background-color: #e15258; }

.tutorial-box-overlay.sujet-nodejs {
  background-color: #95D26C; }

.tutorial-box-overlay.sujet-web {
  background-color: #637a91; }

.tutorial-box-overlay.sujet-entrepreneur {
  background-color: #39ADD1; }

.tutorial-box-overlay {
  display: block;
  position: relative;
  min-height: 250px;
  padding: 20px;
  margin-bottom: 30px;
  color: #FFF;
  background: url("../images/background/intro-cover.png") center rgba(47, 50, 65, 0.9); }
  .tutorial-box-overlay::after {
    content: " ";
    display: table;
    clear: both; }

.tutorial-box_info {
  height: 100%; }

.tutorial-box_author {
  position: relative;
  height: 100%;
  float: right;
  margin-bottom: 1.5625rem;
  font-size: 0.875rem; }

.tutorial-box_info-category {
  margin-top: 50px;
  font-size: 12px;
  text-transform: uppercase; }

.tutorial-box_info-title {
  font-size: 1.9375rem; }

.tutorial-box_author-photo {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -50px;
  border: 2px solid #fff;
  color: #404852; }

.tutorial-box_info-breadcrumb {
  position: absolute;
  bottom: 0;
  padding: 1rem 0; }
  .tutorial-box_info-breadcrumb a {
    color: #FFF; }
    .tutorial-box_info-breadcrumb a:hover {
      color: #a3acb6; }
  .tutorial-box_info-breadcrumb li.active a {
    color: #2a2f35; }
  .tutorial-box_info-breadcrumb li + li::before {
    color: #FFF; }

.count-container {
  display: inline-block;
  float: right; }

.post-edit-admin {
  margin-bottom: 1rem; }

.tutorials-welcome {
  display: flex;
  align-items: center;
  justify-content: center; }
  .tutorials-welcome .welcome {
    width: 800px;
    font-size: 18px; }

.tutorials-error {
  text-align: center; }
  .tutorials-error p {
    margin-bottom: 1rem; }

@media (min-width: 768px) {
  .tutorial-box_info {
    width: 40.66667%; }
  .button-edit,
  .button-add {
    float: right; } }

@media screen and (max-width: 767px) {
  .post-sidebar {
    margin-bottom: 30px; }
  .post-content {
    padding: 0;
    border: 0; } }

/* ==|====================
	 Editeur de wadzaa
	 ======================= */
.markdown-zone h1,
.markdown-zone h2,
.markdown-zone h3,
.markdown-zone h4,
.markdown-zone h5,
.markdown-zone h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25; }

.markdown-zone h1 {
  padding-bottom: 0.3125rem;
  border-bottom: 1px solid rgba(231, 237, 243, 0.8); }

.markdown-zone > *:first-child {
  margin-top: 0; }

.markdown-zone p,
.markdown-zone blockquote,
.markdown-zone ul,
.markdown-zone ol,
.markdown-zone dl,
.markdown-zone table,
.markdown-zone pre {
  margin-top: 0;
  margin-bottom: 16px; }

.markdown-zone p code {
  padding: 2px 4px;
  border-radius: 0.1875em;
  color: #0067d8;
  background-color: #e7edf3; }

.markdown-zone ul,
.markdown-zone ol {
  padding-left: 1.5625rem; }
  .markdown-zone ul:nth-child(1),
  .markdown-zone ol:nth-child(1) {
    margin-bottom: 0px; }

.markdown-zone a {
  color: #0067d8; }
  .markdown-zone a:hover {
    text-decoration: underline; }

.markdown-zone img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  border-radius: 0.1875em;
  border: 8px solid #e7edf3; }

.markdown-zone figure {
  margin-bottom: 20px;
  text-align: center; }
  .markdown-zone figure figcaption {
    width: 67%;
    padding-top: 5px;
    margin: 10px auto 0;
    font-size: 80%;
    font-style: italic;
    border-top: 1px solid #e7edf3;
    color: #90a3b7; }

.content-source {
  padding: 20px;
  border-radius: 0.1875em;
  border: 4px solid #e7edf3;
  background-color: #f6f8fa; }
  .content-source h1,
  .content-source h2,
  .content-source h3,
  .content-source h4,
  .content-source h5,
  .content-source h6 {
    margin-top: 0; }
  .content-source ul {
    margin-bottom: 0 !important;
    list-style: none; }

code.hljs {
  padding: 16px;
  overflow: auto;
  border-radius: 3px;
  color: #404852;
  background-color: #f6f8fa; }

/* ==|====================
	 Sidebar
	 ======================= */
/* ==|====================
	 Demo
	 ======================= */
/* ==|====================
	 Utilities
	 ======================= */
/* ==|====================
	 Default
	 ======================= */
.keyboard_btn {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  color: #333;
  font-size: 11px;
  line-height: 1.4;
  text-shadow: 0 1px 0 #fff;
  font-family: Arial,Helvetica,sans-serif;
  display: inline-block;
  padding: 0.1em 0.6em;
  margin: 0 0.1em;
  white-space: nowrap;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  border-radius: 3px; }
