/* ==|====================
	 Alert
	 ======================= */

.alert {
	padding: rem($alert-padding);
	margin-bottom: rem(14);
	border: $wdz-border_width solid transparent;
	font-size: rem(16);

	@include border-radius(rem($alert-border-radius));
}

.alert-first {
	@include alert($alert-first-text, $alert-first-border, $alert-first-bg);
}

.alert-info {
	@include alert($alert-info-text, $alert-info-border, $alert-info-bg);
}

.alert-success {
	@include alert($alert-success-text, $alert-success-border, $alert-success-bg);
}

.alert-warning {
	@include alert($alert-warning-text, $alert-warning-border, $alert-warning-bg);
}

.alert-danger {
	@include alert($alert-danger-text, $alert-danger-border, $alert-danger-bg);
}

// Outline
.alert-outline-first {
	@include alert-outline($alert-first-text, $alert-first-border);
}

.alert-outline-info {
	@include alert-outline($alert-info-text, $alert-info-border);
}

.alert-outline-success {
	@include alert-outline($alert-success-text, $alert-success-border);
}

.alert-outline-warning {
	@include alert-outline($alert-warning-text, $alert-warning-border);
}

.alert-outline-danger {
	@include alert-outline($alert-danger-text, $alert-danger-border);
}
