/* ==|====================
	 Flexbox
	 ======================= */

// Centrage vertical
.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.aligner-item {
	max-width: 50%;
}

.aligner-item--top {
	align-self: flex-start;
}

.aligner-item--bottom {
	align-self: flex-end;
}
