/* ==|====================
	 Footer
	 ======================= */
.page-footer {
	position: relative;
	padding: 30px 0;
	text-align: center;
	border-top: 1px solid rgba(98, 98, 98, .2);
	background: white;
}

.footer-links {
	li {
		display: inline-block;

		a {
			color: $wdz-body_color;
		}
	}

	li + li {
		&::before {
			content: '|';
			margin: 0 10px;
			color: black;
			opacity: .2;
		}
	}
}
