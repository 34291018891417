/* ==|====================
	 UI Components
	 ======================= */

//== Reset personalisé
//=====================
// Bouton
button {
	appearance: none;
	outline: none;
	border: 0;
	border-radius: 3px;
	cursor: pointer;
	background: transparent;
}

//== Couleurs Annimé
.colours,
.colours-bg {
	animation-iteration-count: infinite;
}
// Colors
.colours {
	animation: colour 55s 30 ease-in-out;
}
// Background Colors
.colours-bg {
	animation: colour-bg 48s 30 ease-in-out;
}
// Colors
@keyframes colour {
	0% { color: #3498db; }
	15% { color: #2ecc71; }
	28% { color: #1abc9c; }
	41% { color: #9b59b6; }
	53% { color: #e74c3c; }
	65% { color: #f39c12; }
	78% { color: #e67e22; }
	90% { color: #2ecc71; }
	100% { color: #3498db; }
}
// Background Colors
@keyframes colour-bg {
	0% { background-color: #3498db; }
	15% { background-color: #2ecc71; }
	28% { background-color: #1abc9c; }
	41% { background-color: #9b59b6; }
	53% { background-color: #e74c3c; }
	65% { background-color: #f39c12; }
	78% { background-color: #e67e22; }
	90% { background-color: #2ecc71; }
	100% { background-color: #3498db; }
}

//== Les class
.no-scroll {
	overflow: hidden;
}

//== Box Panel
//=====================

.mixed-box {
	position: relative;
	padding: 15px;
	border-radius: rem($wdz-border_radius);
	box-shadow: 0 2px 0 rgba(0, 0, 0, .06);
	background: $white;
}

.box {
	position: relative;
	top: 0;
	padding: em(20);
	margin-bottom: em(16);
	border-radius: em($wdz-border_radius);
	box-shadow: 0 2px 0 0 #CCCFDB;
	background: #fff;
	transition: all .2s;
}

//== Card Panel
//=====================
.card-list {
	/* margin: 0 -15px; */
}

.card {
	position: relative;
	display: block;
	// padding: 0 15px;
	margin-bottom: 30px;
}

.card-box {
	display: block;
	position: relative;
	min-height: 225px;
	top: 0;
	padding: 70px 15px 10px;
	border-radius: 5px 5px 4px 4px;
	// box-shadow: 0 2px 0 rgba(0, 0, 0, .06);
	box-shadow: 0 2px 0 0 #CCCFDB;
	transition: all .2s;

	// Hover
	@include hover {
		top: -2px;
		box-shadow: 0 4px 0 0 #CCCFDB;
	}
}

.card-box {
	&:not(.has-topic-background-color) {
		background: $white;
	}
}

// .card-backgroundCover {
.card-cover {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 58px;
	line-height: 58px;
	padding: 0 15px;
	border-radius: 4px 4px 0 0;
	color: #fff;
	// background-color: #717b85;
	background: url('../images/background/intro-cover.png') center rgba(47, 50, 65, .9);
}
// .card-link {}

.card-icon {
	position: relative;
	height: 20px;
	width: 20px;
	top: 5px;
	margin-right: 15px;
	font-size: 25px;
}

.card-reading_time {
	position: absolute;
	padding-left: 10px;
	top: 0px;
	right: 30px;
	font-weight: 500;
	text-align: right;
	color: $white;
}

.card-type {
	display: block;
	margin: 0 0 10px;
	line-height: 12px;
	font-size: 14px;
	color: lighten($wdz-lien, 30%);
}

.card-title {
	margin: 0 0 10px;
	line-height: 24px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 20px;
	color: #4b5658;
}

.card-description {
	position: relative;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.card-actions {
	display: block;
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 10px;
	z-index: 1;
	color: $wdz-lien;

	//
	a {
		@include hover {
			color: $wdz-lien;
		}
	}
}

// Card petit
.card-small {
	.card-box {
		min-height: 150px;
	}
}

// Breadcrumb
// ==|====================
.breadcrumb {
	display: inline-block;
	// padding: 11px 0;
	margin: 0;
	font-size: 14px;
	list-style: none;
	// Icons
	i {
		position: relative;
		top: 1px;
		// right: 2px;
		right: 5px;
		font-size: 16px;
	}

	li {
		display: inline;

		&.active a {
			cursor: default;
			color: #c5cbcc;

			@include hover {
				text-decoration: none;
			}
		}

		a {
			@include hover {
				text-decoration: underline;
			}
		}
	}

	.separator {
		padding: 0 5px;
	}

	li + li::before {
		padding: 0 5px;
		color: #c5cbcc;
		// content: "•";
		content: ">";
	}
}

// Images
// ==|====================

// Perfect circle
.img-circle {
	border-radius: 50%;
}

// Avatars
// ==|====================
.avatar-user {
	display: inline-block;
	box-sizing: content-box;
	text-align: center;
	width: rem(40);
	height: rem(40);
	margin-right: 8px;
	border-radius: rem(40);
	font-size: em(20);
	line-height: 2.5rem;
	color: #FFF;
	background: #e7edf3;
}

// Typograpgy & Tips
// ==|====================
.text-center { text-align: center; }
.uppercase { text-transform: uppercase; }
// Margin
.margin-top {
	margin-top: em(16);
}

//=== Liens Tips
// lien heading
.link-heading {
	margin: 12px 15px;
}

.link-separated {
	display: block;
	height: 1px;
	margin: rem(8) 0;
	overflow: hidden;
	background-color: rgba(0, 0, 0, .05);
}

//== Composant logo
//=====================
.wdz_A { @include hover { fill: #2ecc71; } }
.wdz_B { @include hover { fill: #4A89DC; } }
.wdz_C { @include hover { fill: #5D9CEC; } }
.wdz_D { @include hover { fill: #3BAFDA; } }
.wdz_E { @include hover { fill: #4FC1E9; } }
.wdz_F { @include hover { fill: #37BC9B; } }
.wdz_G { @include hover { fill: #48CFAD; } }
.wdz_H { @include hover { fill: #8CC152; } }
.wdz_I { @include hover { fill: #A0D468; } }
.wdz_J { @include hover { fill: #F6BB42; } }
.wdz_K { @include hover { fill: #FFCE54; } }
.wdz_L { @include hover { fill: #E9573F; } }
.wdz_M { @include hover { fill: #FC6E51; } }
.wdz_N { @include hover { fill: #DA4453; } }
.wdz_O { @include hover { fill: #ED5565; } }
.wdz_P { @include hover { fill: #967ADC; } }
.wdz_Q { @include hover { fill: #AC92EC; } }
.wdz_R { @include hover { fill: #D770AD; } }
.wdz_S { @include hover { fill: #EC87C0; } }

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
// @media (min-width: 768px) {}

// Pour ceux qui ont une résolution inférieure à 767px
// @media screen and (max-width: 767px) {}
