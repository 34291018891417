/* ==|====================
	 landing page
	 ======================= */
.wdz-homepage {
	//
	//== HEADER
	.page-header {
		position: absolute;
		color: white;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	//== HEADER MENU
	.navigation-item.active .navigation-link {
		color: white;
	}
	.navigation-link { color: white; }
}

.hero-welcome {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100vh;
	text-align: center;
	overflow: hidden;
	color: $white;
	background: url('../images/background/bg.jpg') center no-repeat transparent;
	background-size: cover;

	h1 {
		font-size: em(40);
	}

	small {
		font-size: em(25);
	}

	p {
		font-size: em(20);
	}

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .7;
		background: #191919;
	}
}

.hero-inside {
	position: relative;
}

//=== Sections
.section {
	padding-top: 80px;
	padding-bottom: 80px;
	background: #fff;

	.container {
		max-width: 100%;
	}
}

.section-heading {
	max-width: 700px;
	margin: 0 auto 50px;
	text-align: center;

	small {
		display: inline-block;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-bottom: rem(16);
		opacity: .6;
	}
}

// .section-title {}
.section-separator {
	height: 2px;
	width: 80px;
	margin: 20px auto auto;
	background-color: rgba(0, 0, 0, .07);
}

.section-welcome {
	max-width: 612px;
	margin: 0 auto;
	line-height: 28px;
	text-align: center;
}

//== Sections Services
.topic-cst { color: $service-blue; }
.topic-cst_background-color { background: $service-blue; }

.topic-arq { color: $service-red; }
.topic-arq_background-color { background: $service-red; }

.topic-srm { color: $service-green; }
.topic-srm_background-color { background: $service-green; }

.services-item {
	display: block;
	position: relative;
	margin-bottom: 30px;
	text-align: center;
}

.services-item_link {
	display: block;
	position: relative;
	padding: 30px 20px;
	min-height: 200px;
	border-radius: 4px;
	background: $white;

	@include hover {
		color: $wdz-lien;
	}
}

.services-item_title {
	// margin-bottom: rem(15);
	font-size: 22px;
}

.services-item_description {
	margin-top: rem(25);
	// font-size: rem(14);
}

//== Sections Banners
.section-banner {
	padding-top: rem(40);
	padding-bottom: rem(40);
	color: white;
	background: rgba(0, 0, 0, .1);
}

// Second version de banner
.banner-contact {
	padding-top: rem(40);
	padding-bottom: rem(40);
	text-align: center;

	.button {
		margin-bottom: rem(5);
	}

	small {
		color: #b5b9bf;

		a {
			color: $blue;
		}
	}
}

.section-banner_inner {
	h2 { margin-bottom: rem(16); }
}

.button-banner {
	color: white;
	background-image: none;
	background-color: transparent;
	border-color: white;

	@include hover-focus {
		color: black;
		background-color: white;
		border-color: white;
	}
}

// Colors
.bg-grey {
	background-color: #f8f8f8;
}
