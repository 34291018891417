/* ==|====================
	 Contact
	 ======================= */
.contact-content {
	p {
		margin: 10px 0;
		font-size: rem(14);
	}

	.input-label {
		margin-bottom: 2px;
	}

	textarea {
		min-height: 110px;
		resize: vertical;
	}

	.button {
		margin-top: 10px;
	}
}
