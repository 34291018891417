/* ==|====================
   Typography, Headings
   ======================= */

h1 { font-size: rem($font-size-h1); }
h2 { font-size: rem($font-size-h2); }
h3 { font-size: rem($font-size-h3); }
h4 { font-size: rem($font-size-h4); }
h5 { font-size: rem($font-size-h5); }
h6 { font-size: rem($font-size-h6); }

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: $headings-margin-bottom;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;
}
