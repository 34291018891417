/* ==|====================
	 Header
	 ======================= */

.page-header {
	position: relative;
	min-height: 45px;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: $wdz-zindex_header;
	background: #FFF;
	// box-shadow: 1px 0px 20px rgba(0, 0, 0, .08);
	border-bottom: 1px solid rgba(212, 218, 223, .2);
}

//== HEADER NAV
//======================

//== HEADER MENU
//======================

// Nom du logo
.mobile-name-logo { display: none; }

//=== Section header
.section-header {
	display: flex;
	align-items: center;
}

.main-navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 55px;
	padding: 0 30px;
}

.navigation-menu {
	display: flex;
}

.navigation-item {
	// Lien active
	&.active {
		.navigation-link {
			color: $wdz-lien_hover;
			opacity: 1;
		}
	}
}

.navigation-link {
	padding: 0 16px;
	// padding: 0 8px;
	text-transform: uppercase;
	font-size: 14px;
	opacity: .7;
	transition: .3s;
	// Hover
	@include hover {
		opacity: 1;
	}
}

//== LOGO
//======================
.logo {
	height: 45px;
	width: auto;
}

.logo-link {
	display: flex;
	align-items: center;
}

//== Hamburger menu toggle
//========================
.button-menu-mobile {
	display: none;
	margin-left: 20px;
}

.hamburger {
	width: 30px;
	height: 30px;
}

.hamburger-bar {
	display: block;
	position: absolute;
	width: 22px;
	height: 3px;
	border-radius: 2px;
	background-color: #AAA;
	transition: .3s ease-in-out;

	// Before et after
	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 22px;
		height: 3px;
		border-radius: 2px;
		background-color: #AAA;
		transition: .3s ease-in-out;
	}

	// Before
	&::before {
		top: -7px;
	}
	// After
	&::after {
		bottom: -7px;
	}
}

// Menu ouvert
.show-menu {
	.hamburger-bar {
		background-color: transparent;

		// Before
		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		// After
		&::after {
			bottom: 0;
			transform: rotate(-45deg);
		}
	}
}

//== Bouton de connexion et d'inscription
//=======================================
.header-connexion {
	font-size: 14px;

	button {
		color: $wdz-lien;
	}
}

.signin-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.signin-icon-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(48);
	width: rem(48);
}

.icon-background-circle {
	position: absolute;
	height: 28px;
	width: 28px;
	border-radius: 50%;
	background: #464d51;
}

.signin-icon {
	display: block;
	position: absolute;
	font-size: 30px;
	color: #e7edf3;
}

// Bouton s'inscrire
.sign-up {
	padding: 5px 10px;
	margin-top: -5px;
	margin-left: 10px;
	font-size: 14px;
	border: 0;
	border: 2px solid $blue;
	color: $blue;
	transform: translateZ(0);
	// Before, After
	&::after,
	&::before {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 0;
		z-index: -1;
		content: "";
		background: $blue;
		transition: all 300ms ease-in-out;
	}
	&::before { left: 0; }
	&::after { right: 0; }
	// Hover
	@include hover {
		color: #FFF;

		&::before,
		&::after { width: 51%; }
	}
}

// Bouton connecté
.connected-button {
	display: flex;
	align-items: center;
}

// Bouton se déconnecter
.logout {
	margin-left: rem(16);
	color: $danger;

	@include hover {
		color: darken($danger, 10%);
	}
}

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
// @media (min-width: 768px) {}

// Pour ceux qui ont une résolution inférieure à 767px
@media screen and (max-width: 767px) {
	//== HEADER MENU
	// Nom du logo
	.mobile-name-logo {
		display: block;
		padding: 0 25px;
		line-height: 55px;
		font-weight: 500;
		letter-spacing: 25px;
		text-transform: uppercase;
		text-align: center;
		color: #FFF;
	}

	.navigation {
		position: fixed;
		width: 250px;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1100;
		transform: translate3d(-250px, 0, 0);
		background: $blue;
	}

	.navigation-menu {
		position: absolute;
		flex-direction: column;
		width: 100%;
		height: 100%;
		top: 55px;
	}

	.navigation-item {
		// Lien active
		&.active {
			.navigation-link {
				color: $blue;
				background: #FFF;
			}
		}

		// Animation
		&:nth-child(1) { transform: translate3d(0, 1000px, 0); }
		&:nth-child(2) { transform: translate3d(0, 1500px, 0); }
		&:nth-child(3) { transform: translate3d(0, 2100px, 0); }
		&:nth-child(4) { transform: translate3d(0, 2800px, 0); }
		&:nth-child(5) { transform: translate3d(0, 3600px, 0); }
		&:nth-child(6) { transform: translate3d(0, 4500px, 0); }
		&:nth-child(7) { transform: translate3d(0, 5500px, 0); }
	}

	.navigation-link {
		display: block;
		width: 100%;
		padding: 10px 20px;
		opacity: 1;
		color: #FFF;

		// Hover
		@include hover {
			color: $blue;
			background: #FFF;
		}
	}

	.button-text { display: none; }

	//== Hamburger menu toggle
	.button-menu-mobile { display: block; }

	// Menu open
	.show-menu {
		.navigation {
			transform: translate3d(0, 0, 0);
			transition: transform .8s;
			transition-timing-function: cubic-bezier(.7, 0, .3, 1);
		}

		.navigation-item {
			transform: translate3d(0, 0, 0);
			transition: transform .8s;
			transition-duration: .9s;
		}

		// Main content
		.main-content {
			&::before {
				z-index: 10;
				opacity: 1;
				background: rgba(0, 0, 0, .5);
			}
		}
	}
	// Main content
	.main-content {
		&::before {
			/* position: absolute; */
			position: fixed;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			opacity: 0;
			transition: opacity .8s;
			transition-timing-function: cubic-bezier(.7, 0, .3, 1);
		}
	}
}
