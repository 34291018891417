/* ==|====================
   Forms
   ======================= */

.input-label {
	display: inline-block;
	margin-bottom: rem(8);
}

.input-text,
textarea {
	display: block;
	width: 100%;
	padding: rem(8) rem(12);
	font-size: rem($font-size-base);
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-image: none;
	border: $border-width solid #ccc;
	border-radius: rem($border-radius);

	@include focus {
		border-color: #0DAFE0;
		outline: none;
	}
}

select {
	&:not([size]):not([multiple]) {
		height: rem($input-height);
	}

	&:focus::-ms-value {
		color: $input-color;
		background-color: $input-bg;
	}
}

.form-item {
	position: relative;
	margin-bottom: rem($form-group-margin-bottom);
}

.form-inline {
	.form-item {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
	}

	.input-text,
	textarea {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	}
}

// Forms personalisé
// ==|=====================================

.form-text-input {
	width: 100%;
	padding: 10px 15px;
	margin: 0;
	outline: 0;
	border-radius: 5px;
	box-sizing: border-box;
	border: 2px solid #e8ebed;
	// color: #576366;
	background: #e8ebed;
	transition: .2s;
}

.input-text,
textarea {
	@include focus {
		background: #fff;
		border-color: #d4d9dd;
	}
}

