/* ==|====================
   ResetCss | Réinitialisation
   ======================= */

// Document |
// ==========================================================================

html {
	box-sizing: border-box;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

// Sections |
// ==========================================================================

body {
	font-family: sans-serif;
	line-height: 1.5;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
}

main,
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
	display: block;
}

small {
	font-size: 80%;
}

a {
	background-color: transparent;
}

a:active,
a:hover {
	outline-width: 0;
}

img {
	border-style: none;
	vertical-align: middle;
}

svg:not(:root) {
	overflow: hidden;
}

ul {
	list-style: none;
}

input[type="radio"] {
	vertical-align: text-bottom;
}

input[type="checkbox"] {
	vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
	vertical-align: baseline;
}

.ie6 input {
	vertical-align: text-bottom;
}
