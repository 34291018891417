/* ==|====================
	 Sidebar
	 ======================= */

//== FRONT END
//============

//== BACK END
//===========

// RESPONSIVE
// Pour ceux qui ont une résolution supérieure à 768px
// @media (min-width: 768px) {}

// Pour ceux qui ont une résolution inférieure à 767px
@media screen and (max-width: 767px) {
	//== FRONT END
	//============

	//== BACK END
	//===========
}
