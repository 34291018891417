/* ==|====================
	 Variables
	 ======================= */

// = Colors
// -----------------------------

// color elements
$white:                       #FFF !default;
$black:                       #000 !default;

$blue-dark:                   #4A89DC !default;
$blue-light:                  #5D9CEC !default;

$aqua-dark:                   #3BAFDA !default;
$aqua-light:                  #4FC1E9 !default;

$mint-dark:                   #37BC9B !default;
$mint-light:                  #48CFAD !default;

$grass-dark:                  #8CC152 !default;
$grass-light:                 #A0D468 !default;

$sunflower-dark:              #F6BB42 !default;
$sunflower-light:             #FFCE54 !default;

$bittersweet-dark:            #E9573F !default;
$bittersweet-light:           #FC6E51 !default;

$grapefruit-dark:             #DA4453 !default;
$grapefruit-light:            #ED5565 !default;

$lavender-dark:               #967ADC !default;
$lavender-light:              #AC92EC !default;

$pinkrose-dark:               #D770AD !default;
$pinkrose-light:              #EC87C0 !default;

$lightgray-dark:              #E6E9ED !default;
$lightgray-light:             #F5F7FA !default;

$mediumgray-dark:             #AAB2BD !default;
$mediumgray-light:            #CCD1D9 !default;

$darkgray-dark:               #434A54 !default;
$darkgray-light:              #656D78 !default;

$first: 							        $blue-dark;
$secondary: 					        $darkgray-light;
$danger: 							        $grapefruit-dark;
$success: 						        $grass-dark;
$warning: 						        $sunflower-dark;
$info:                        $aqua-dark;
$dark: 								        #2f353b;
$white: 							        $white;

//
// Paramètres <body>.
$body-bg:     $white !default;
$body-color:  $darkgray-dark !default;

// Links
//
$link-color:            $first !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Typographie
//
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$font-size-base:  16 !default;

$line-height-base:  1.5 !default;

// ==|====================
// 10px = 0.625rem
// 12px = 0.75rem
// 14px = 0.875rem
// 16px = 1rem (base)
// 20px = 1.25rem
// 24px = 1.5rem
// 28px = 1.75rem
// 32px = 2rem

$font-size-h1:  32 !default;
$font-size-h2:  28 !default;
$font-size-h3:  24 !default;
$font-size-h4:  20 !default;
$font-size-h5:  16 !default;
$font-size-h6:  14 !default;

$headings-margin-bottom: .5rem !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

// Components
//
// Défini des formats courants de padding et border radius et bien plus encore.

$border-radius: 2 !default;

$border-width: 1px !default;

// Buttons

$button-line-height:  1.25 !default;
$button-font-weight:  normal !default;

$button-border-radius: $border-radius !default;

$button-first-bg:             $first !default;
$button-info-bg:             	$info !default;
$button-success-bg:           $success !default;
$button-warning-bg:           $warning !default;
$button-danger-bg:            $danger !default;
$button-dark-bg:           	  $dark !default;

$button-first-border:         $button-first-bg !default;
$button-info-border:         	$button-info-bg !default;
$button-success-border:       $button-success-bg !default;
$button-warning-border:       $button-warning-bg !default;
$button-danger-border:        $button-danger-bg !default;
$button-dark-border:          $button-dark-bg !default;

// Forms

$input-line-height:           1.25 !default;

$form-group-margin-bottom:    16 !default;

$input-height:                40 !default;
$input-color:                 $darkgray-light !default;
$input-bg:                    $white;

$cursor-disabled: not-allowed !default;

// Grid

//== Points d’arrêt de grille
$grid-breakpoints: (
s: 0,// Extra small screen / phone
m: 768px,// Medium screen / tablet
l: 1024px,// Large screen / desktop
//xl: 1200px // Extra large screen / wide desktop
) !default;

// Conteneurs grille
// Définir la largeur maximale de `.container` pour différentes tailles d’écran.
$container-max-widths: (
m: 720px,
l: 1140px
) !default;

// Colonnes de la grille
// Définir le nombre de colonnes et de spécifier la largeur des gouttières.
$grid-columns:      12 !default;
$grid-gutter-width: 30px !default;

// ==|====================
// Nos variables personalisé
// =======================

// = Couleurs
// -----------------------------
$wdz-body_bg: #f6f7f8 !default;
$wdz-body_color: #404852 !default;

// INFO NOS COULEURS DEFAULT
// Default: #0086BA => Bleu | #00A87E => Vert Couleur de base
// Default #0067d8 => Bleu lien articles
// Bordure: #d4dadf
// Box cover tutoriel: #2f3241
// default gris: #e7edf3 | Peut etre utilisé pour toute compasant de finition

// $wdz-lien: #737373 !default; Ancien
$wdz-lien: #576366 !default;

$wdz-lien_hover: darken($wdz-lien, 15%) !default;
// $wdz-bg_hover: rgba(238, 238, 238, .7) !default;
$wdz-bg_hover: rgba(238, 238, 238, .3) !default;

// $wdz-border_colors: #ededed !default;
$wdz-border_colors: rgba(231, 237, 243, .8) !default;

// Couleurs logos
$wdz-noir_logo_5: #2d3035 !default;

$green: #00A87E !default;
$blue: #0086BA !default;

// Couleurs section service

$service-blue: #39ADD1 !default;
$service-red: #c25975 !default;
$service-green: #2c9676 !default;

// Couleurs des langues
// ==|====================

$html: #eb7728 !default;
$css: #e59a13 !default;
$javascript: #ffd78f !default;
$ruby: #e15258 !default;
$php: #7D669E !default;
$nodejs: #95D26C !default;
$entrepreneur: #39ADD1 !default;
$web: #637a91 !default;

// Bordures
$wdz-border_radius: 3 !default;
$wdz-border_width: 2px !default;

// = Header
// -----------------------------
$wdz-header_height: 55 !default;

// = Z index
$wdz-zindex_header: 1000 !default;
$wdz-zindex_menu: 1100 !default;
$wdz-zindex_footer: 1000 !default;

// = COMPOSANTS
// -----------------------------

// Racourci couleurs
$wdz-first: $blue-dark;
$wdz-secondary: $darkgray-light;
$wdz-danger: $grapefruit-dark;
$wdz-success: $grass-dark;
$wdz-warning: $sunflower-dark;
$wdz-info: $aqua-dark;
$wdz-dark: #2f353b;
$wdz-white: $white;

// Alerts
$alert-padding: 7 !default;
$alert-border-radius: $wdz-border_radius !default;
$alert-link-font-weight: bold !default;
$alert-border-width: $border-width !default;

$alert-first-text: darken($wdz-first, 20%) !default;
$alert-first-bg: lighten($wdz-first, 20%) !default;
$alert-first-border: darken($alert-first-bg, 5%) !default;

$alert-success-text: darken($wdz-success, 20%) !default;
$alert-success-bg: lighten($wdz-success, 20%) !default;
$alert-success-border: darken($alert-success-bg, 5%) !default;

$alert-info-text: darken($wdz-info, 20%) !default;
$alert-info-bg: lighten($wdz-info, 20%) !default;
$alert-info-border: darken($alert-info-bg, 7%) !default;

$alert-warning-text: darken($wdz-warning, 20%) !default;
$alert-warning-bg: lighten($wdz-warning, 20%) !default;
$alert-warning-border: darken($alert-warning-bg, 5%) !default;

$alert-danger-text: darken($wdz-danger, 20%) !default;
$alert-danger-bg: lighten($wdz-danger, 20%) !default;
$alert-danger-border: darken($alert-danger-bg, 5%) !default;
