/* ==|====================
	 Connexion / s'inscrire
	 ======================= */

// Formulaire de connexion et d'inscription
// ==|=====================================

.login-content {
	// width: 450px;
	width: 515px;
}

.login,
.register,
.requestSetNewPassword {
	// TT
	// color: #576366;
	// Lien formulaire
	a {
		@include hover {
			text-decoration: underline;
		}
	}

	.input-label {
		margin-bottom: 2px;
		font-weight: 500;
	}
}

.form-heading,
.form-footer {
	p {
		font-size: 14px;
	}
}

// Header formulaire
.form-heading {
	position: relative;
	padding-bottom: 15px;

	h1 {
		font-size: 20px;
		color: darken($wdz-lien, 20%);
	}

	p {
		margin: 5px 0 0;
		line-height: 1.6;
	}
}

// Footer formulaire
.form-footer {
	padding: rem(10) rem(15);
	margin: rem(25) rem(-15) rem(-10) rem(-15);
	border-top: $border-width solid $wdz-border_colors;
}

//== Page d'inscription

// Div de d'inscription

//== Mots de passe perdu
p.forgot-password {
	// margin: 11px 0 0;
	padding: 8px 0;
	float: right;
	line-height: 1.25;
	font-size: 14px;
	color: #7b8b8e;
}

// Autres helpers logins
.wdz-login_ {
	// contenu login
	.login-content {
		width: 100%;
	}
}
