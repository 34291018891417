@charset "UTF-8";

// UTILS CORE - Configuration et aides
// ==========================================================================

@import
	"abstracts/variables",
	"abstracts/functions",
	"abstracts/mixins";

// VENDORS - Vendors et les feuilles de style externes
// ==========================================================================

//@import
//"vendor/normalize"; 

// BASE - Trucs de base + | Reset et dépendances
// ==========================================================================

@import
	"base/ResetCss",
	"icons/icons",

	"base/base",
	"base/typography";

// CORE - CSS
// ==========================================================================

@import
	"core/grid",
	"core/nav",
	"core/buttons",
	"core/forms";

// COMPONENTS - Composants
// ==========================================================================

@import
	"components/alert",
	"components/flexbox",
	"components/ui-components";

// LAYOUT - Sections relatives à la disposition
// ==========================================================================

@import
	"layout/header",
	"layout/content",
	"layout/footer",
	"layout/dashboard";

// PAGES - Styles de page spécifique
// ==========================================================================

@import
	"pages/codes",
	"pages/landing",
	"pages/login",
	"pages/contact",
	"pages/tutorials",
	"pages/wadzaaEditors",
	"pages/sidebar",
	"pages/demo";

// Utility classes
// Utilitaire - Classes utilitaires
// ==========================================================================

@import
	"utilities/utilities";

// THEMES - Thèmes
// ==========================================================================

@import
	"themes/default";

	
	
// Keyboard button
// ==========================================================================
.keyboard_btn {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  color: #333;
  font-size: 11px;
  line-height: 1.4;
  text-shadow: 0 1px 0 #fff;
  font-family: Arial,Helvetica,sans-serif;
  display: inline-block;
  padding: 0.1em 0.6em;
  margin: 0 0.1em;
  white-space: nowrap;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  border-radius: 3px;
}

