/* ==|====================
	 Editeur de wadzaa
	 ======================= */

// [Ce fichier contiendra tout le style de l'editeur de wadzaa]
// ==|=======================================================

//== [Backend] L'EDITEUR DE MARKDOWN WADZAA 
//==========================================

//== [Frontend] L'EDITEUR DE MARKDOWN WADZAA 
//==========================================
.markdown-zone {
	// Les Titres
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: 600;
		line-height: 1.25;
	}

	h1 {
		padding-bottom: rem(5);
		border-bottom: 1px solid $wdz-border_colors;
	}

	> *:first-child {
		margin-top: 0;
	}
	// Les Paragraphes
	p,
	blockquote,
	ul,
	ol,
	dl,
	table,
	pre {
		margin-top: 0;
		margin-bottom: 16px;
	}

	p {
		// Codes
		code {
			padding: 2px 4px;
			border-radius: em($wdz-border_radius);
			color: #0067d8;
			background-color: #e7edf3;
		}
	}

	// Listes a puce
	ul,
	ol {
		padding-left: rem(25);
		// Supprimé margin bottom au premiere ul/ol dans le ul/ol parent
		&:nth-child(1) {
			margin-bottom: 0px;
		}
	}

	// Liens
	a {
		color: #0067d8;

		@include hover {
			text-decoration: underline;
		}
	}

	// Images
	img {
		display: block;
		// width: 350px;
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
		border-radius: em($wdz-border_radius);
		border: 8px solid #e7edf3;
	}

	figure {
		margin-bottom: 20px;
		text-align: center;

		figcaption {
			width: 67%;
			padding-top: 5px;
			margin: 10px auto 0;
			font-size: 80%;
			font-style: italic;
			border-top: 1px solid #e7edf3;
			color: #90a3b7;
		}
	}
}

// Contenu des sources extérieure
.content-source {
	padding: 20px;
	border-radius: em($wdz-border_radius);
	border: 4px solid #e7edf3;
	background-color: #f6f8fa;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0;
	}

	ul {
		margin-bottom: 0 !important;
		list-style: none;
	}
}

//== Style highlight [Plutard ici notre propre style]
code.hljs {
	padding: 16px;
	overflow: auto;
	border-radius: 3px;
	color: $wdz-body_color;
	background-color: #f6f8fa;
}
